import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './panel.css';

export const PanelType = {
  DEFAULT: 'default',
  JADE: 'jade',
  SLATE: 'slate',
  CHARCOAL: 'charcoal',
  SILVER_BORDER: 'silver-border',
  JADE_BORDER: 'jade-border',
  SCARLET_BORDER: 'scarlet-border',
  INFORMATION: 'information',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const Panel = ({
  component: Component = 'div',
  type = PanelType.DEFAULT,
  marginBottom = 4,
  fullHeight,
  collapseOnSmallViewport = false,
  children,
  ...rest
}) => (
  <Component
    className={cn(
      styles.Panel,
      {
        [styles[type]]: type,
        [styles.fullHeight]: fullHeight,
        [styles.collapseOnSmallViewport]: collapseOnSmallViewport,
      },
      getMarginBottomClass(marginBottom)
    )}
    {...rest}
  >
    {children}
  </Component>
);

Panel.displayName = 'Panel';

Panel.propTypes = {
  component: PropTypes.string,
  type: PropTypes.oneOf([
    PanelType.DEFAULT,
    PanelType.JADE,
    PanelType.SLATE,
    PanelType.CHARCOAL,
    PanelType.SILVER_BORDER,
    PanelType.JADE_BORDER,
    PanelType.SCARLET_BORDER,
    PanelType.INFORMATION,
    PanelType.ERROR,
    PanelType.SUCCESS,
    PanelType.WARNING,
  ]),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  fullHeight: PropTypes.bool,
  collapseOnSmallViewport: PropTypes.bool,
  children: PropTypes.node,
};

export { Panel };

export default Panel;
