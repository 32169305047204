import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';

import styles from './button-row.css';

export const ButtonRowAlign = {
  LEFT: 'left',
  RIGHT: 'right',
};

const ButtonRow = ({
  align = ButtonRowAlign.RIGHT,
  children,
  marginBottom = 0,
}) => (
  <ul
    className={cn(
      styles.ButtonRow,
      styles[`align-${align}`],
      getMarginBottomClass(marginBottom)
    )}
  >
    {React.Children.map(children, (child) => (
      <li className={styles.button}>{child}</li>
    ))}
  </ul>
);

ButtonRow.displayName = 'ButtonRow';

ButtonRow.propTypes = {
  align: PropTypes.oneOf([ButtonRowAlign.LEFT, ButtonRowAlign.RIGHT]),
  children: PropTypes.node,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export default ButtonRow;
