/* eslint-env browser */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';
import isPlainObject from 'lodash/fp/isPlainObject';
import {
  setFocus,
  eventBus,
  withTrackingContext,
  EventBusType,
} from '@piggybank/core';
import { Provider } from './context';
import WizardPage from '../WizardPage';

export class Wizard extends Component {
  static propTypes = {
    name: PropTypes.string,
    basePath: PropTypes.string,
    children: PropTypes.node.isRequired,
    disablePrevious: PropTypes.bool,
    history: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    initialValues: PropTypes.object,
    /**
     * proxied to eventBus
     */
    onSubmit: PropTypes.func.isRequired,
    /**
     * proxied to eventBus
     */
    onNavigate: PropTypes.func,
    setWizardValues: PropTypes.func,
    /**
     * Turns off forcing user to navigate to last validated page when using browser back button.
     */
    skipLastPageValidation: PropTypes.bool,
    isTrackingInUse: PropTypes.bool,
    /**
     * Initial value for current page.
     */
    initialCurrentPage: PropTypes.number,
  };

  static defaultProps = {
    initialCurrentPage: 0,
    basePath: '',
    disablePrevious: false,
    history: null,
    skipLastPageValidation: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: props.initialCurrentPage,
      lastVisitedPage: null,
      lastValidPage: null,
      values: props.initialValues ? props.initialValues : {},
    };

    this.unsubmittedValues = {};

    if (props.history) {
      this.unlisten = this.props.history.listen(this.handleHistoryListen);
    }

    this.ref = createRef();
  }

  componentDidMount = () => {
    setFocus(this.ref.current);
    window.scrollTo(0, 0);
    this.onNavigate();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.currentPage !== prevState.currentPage) {
      // Set focus only after page transition
      setFocus(this.ref.current);
      window.scrollTo(0, 0);
    }
  };

  componentWillUnmount = () => {
    if (this.props.history) {
      this.unlisten();
    }
  };

  handleHistoryListen = (location, action) => {
    const { currentPage, lastValidPage } = this.state;
    const { skipLastPageValidation } = this.props;

    // if location state has not been populated === first wizard page
    // else will have component === wizard

    const isWizard =
      typeof location.state !== 'object' ||
      (location.state && location.state.component === 'Wizard')
        ? true
        : false;

    if (isWizard) {
      let newPageIndex = location.state ? location.state.currentPage : 0;

      if (!skipLastPageValidation) {
        if (action === 'POP' && newPageIndex >= lastValidPage) {
          // When navigating with forward and back browser buttons always force user to lowest of currentPage and lastValidPage
          newPageIndex = Math.min(currentPage, lastValidPage);
        } else {
          // Otherwise when using Navigation increment lastValidPage every time a page is
          // successfully completed (and validated) by the user
          if (lastValidPage !== null && newPageIndex > lastValidPage + 1) {
            newPageIndex = lastValidPage;
          }
        }
      }

      if (currentPage !== newPageIndex) {
        this.setState({
          currentPage: newPageIndex,
          lastVisitedPage: currentPage,
        });
      }
    }
  };

  setValues = (values, callback) => {
    this.unsubmittedValues = omit(Object.keys(values), this.unsubmittedValues);

    const { setWizardValues, isTrackingInUse } = this.props;
    if (isTrackingInUse) {
      setWizardValues({ ...this.state.values, ...values });
    }

    this.setState(
      (state) => ({
        values: { ...state.values, ...values },
      }),
      callback
    );
  };

  setUnsubmittedValues = (values) => {
    this.unsubmittedValues = omit(Object.keys(this.state.values), values);
  };

  setInitialValues = (defaults) => {
    const { values } = this.state;

    // Merge defaults with values and unsubmitted values from Wizard
    return {
      ...defaults,
      ...pick(Object.keys(defaults), this.unsubmittedValues),
      ...values,
    };
  };

  previous = (values) => {
    const currentPage = Math.max(this.state.currentPage - 1, 0);

    if (isPlainObject(values)) {
      this.setValues(values);
    }

    this.navigateTo(currentPage);
  };

  next = (values, lastPage = false) => {
    const { children, skipLastPageValidation } = this.props;

    this.setValues(values);

    if (!lastPage) {
      const wizardPages = this.getWizardPages(children);
      const pageCount = wizardPages.length;
      const currentPage = Math.min(this.state.currentPage + 1, pageCount - 1);

      if (!skipLastPageValidation) {
        this.setState(() => ({ lastValidPage: currentPage - 1 }));
      }
      this.navigateTo(currentPage);
    }
  };

  handleNext = ({ values } = {}) => {
    const { children, onSubmit } = this.props;
    const { currentPage } = this.state;

    const pageCount = this.getWizardPages(children).length - 1;
    const isLastPage = currentPage === pageCount;

    this.next(values && !values.currentTarget ? values : {}, isLastPage);

    if (isLastPage) {
      eventBus.dispatch({
        type: EventBusType.ON_SUBMIT,
        component: 'Wizard',
        values: this.state.values,
        name: this.props.name,
      });
      return onSubmit(this.state.values, this.props.history);
    }
  };

  navigateTo = (index, values) => {
    this.setState(
      {
        currentPage: index,
        lastVisitedPage: this.state.currentPage,
      },
      () => {
        if (this.props.history) {
          this.props.history.push({
            pathname: this.props.basePath,
            search: window.location.search,
            state: { currentPage: index, component: 'Wizard' },
          });
        }
        this.onNavigate();
      }
    );

    // If optional values are provided then add them to the state
    if (values) {
      this.setValues(values);
    }
  };

  onNavigate = () => {
    const { children, onNavigate } = this.props;
    const { values, currentPage, lastVisitedPage } = this.state;

    const wizardPages = this.getWizardPages(children);

    const navigationInfo = {
      currentPageIndex: currentPage,
      lastVisitedPageIndex: lastVisitedPage,
      totalPages: wizardPages.length,
      values,
    };

    const currentPageName = get(`[${currentPage}].props.name`, wizardPages);

    const lastVisitedPageName = get(
      `[${lastVisitedPage}].props.name`,
      wizardPages
    );

    if (currentPageName) {
      navigationInfo.currentPageName = currentPageName;
    }

    if (lastVisitedPageName) {
      navigationInfo.lastVisitedPageName = lastVisitedPageName;
    }

    eventBus.dispatch({
      type: EventBusType.ON_NAVIGATE,
      component: 'Wizard',
      ...navigationInfo,
    });

    if (onNavigate) {
      onNavigate(navigationInfo);
    }
  };

  getWizardComponents = (children) =>
    React.Children.toArray(children).filter(
      (child) => (<WizardPage />).type !== child.type
    );

  getWizardPages = (children) =>
    React.Children.toArray(children).filter(
      (child) => (<WizardPage />).type === child.type
    );

  render() {
    const { children, disablePrevious } = this.props;
    const { currentPage, values } = this.state;
    const wizardPages = this.getWizardPages(children);
    const activePage = wizardPages[currentPage];
    const wizardComponents = this.getWizardComponents(children);
    const isLastPage = currentPage === wizardPages.length - 1;

    return (
      <div ref={this.ref}>
        <Provider
          value={{
            activePage,
            currentPage: this.state.currentPage,
            lastVisitedPage: this.state.lastVisitedPage,
            disablePrevious,
            onNext: this.handleNext,
            onPrevious: this.previous,
            onSubmit: this.handleNext,
            isLastPage,
            navigateTo: this.navigateTo,
            onChange: this.handleChange,
            setInitialValues: (defaults) => this.setInitialValues(defaults),
            setValues: this.setValues,
            setUnsubmittedValues: this.setUnsubmittedValues,
            totalPages: wizardPages.length,
            values,
          }}
        >
          {wizardComponents}
          {activePage}
        </Provider>
      </div>
    );
  }
}

export default withTrackingContext(
  pick(['setWizardValues', 'isTrackingInUse'])
)(Wizard);
