import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { default as eventBus, EventBusType } from '../../utils/eventBus';
import links from '../../utils/withMagicUnderlines.css';
import styles from './link.css';
import ButtonLinkBase from '../ButtonLinkBase';

export const LinkElementType = {
  BUTTON: 'button',
  A: 'a',
};

const Link = React.forwardRef(
  (
    {
      elementType = LinkElementType.A,
      children,
      iconSlotLeft,
      iconSlotRight,
      strong = false,
      buttonPadding = false,
      hoverUnderline,
      onClick,
      name,
      ...rest
    },
    ref
  ) => {
    return (
      <ButtonLinkBase
        className={cn(styles.Link, {
          [styles['Link--strong']]: strong,
          [styles['Link--likeButton']]: elementType === LinkElementType.BUTTON,
          [styles['Link--buttonPadding']]:
            elementType === 'button' && buttonPadding,
          [styles['Link--withIconSlotLeft']]: iconSlotLeft,
          [styles['Link--withIconSlotRight']]: iconSlotRight,
          [links.withMagicUnderlinesInverted]: hoverUnderline,
        })}
        elementType={elementType}
        iconSlotLeft={iconSlotLeft}
        iconSlotRight={iconSlotRight}
        onClick={(e) => {
          const text = e.target.textContent || '';
          eventBus.dispatch({
            type: EventBusType.ON_CLICK,
            component: 'Link',
            text: text.trim(),
            name,
          });
          onClick && onClick(e);
        }}
        ref={ref}
        {...rest}
      >
        <span
          className={cn(
            hoverUnderline
              ? links.withMagicUnderlinesChild
              : links.withMagicUnderlines,
            {
              [styles['text']]: elementType === LinkElementType.BUTTON,
            }
          )}
        >
          {children}
        </span>
      </ButtonLinkBase>
    );
  }
);

Link.displayName = 'Link';

Link.propTypes = {
  elementType: PropTypes.oneOf([LinkElementType.BUTTON, LinkElementType.A]),
  /**
   * proxied to eventBus
   */
  onClick: PropTypes.func,
  component: PropTypes.func,
  iconSlotLeft: PropTypes.node,
  iconSlotRight: PropTypes.node,
  children: PropTypes.node,
  /**
   * To be used in conjunction with `elementType = 'a'`
   */
  href: PropTypes.string,
  strong: PropTypes.bool,
  /**
   * To be used in conjunction with `elementType = 'button'`
   */
  buttonPadding: PropTypes.bool,
  hoverUnderline: PropTypes.bool,
  /**
   * To be used in conjunction with `elementType = 'a'`
   */
  external: PropTypes.bool,
  textMap: PropTypes.shape({
    newWindow: PropTypes.string,
  }),
  name: PropTypes.string,
  /**
   * To be used in conjunction with `elementType = 'a'`
   */
  target: PropTypes.string,
  /**
   * To be used in conjunction with `elementType = 'a'`
   */
  rel: PropTypes.string,
};

Link.defaultProps = {
  elementType: LinkElementType.A,
  strong: false,
  buttonPadding: false,
  textMap: {
    newWindow: 'Opens in a new window',
  },
};

export { Link };

export default Link;
