import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { marginBottomLevels } from '../../utils/marginBottom';
import Panel from '../Panel';
import {
  IconStatusConfirmationOnlight,
  IconStatusInformationOnlight,
  IconStatusWarningOnlight,
  IconStatusErrorOnlight,
} from '../Icon';

import styles from './callout.css';

export const CalloutType = {
  KEY_INFORMATION: 'key-information',
  INFORMATION: 'information',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const calloutTypeIconMap = {
  [CalloutType.INFORMATION]: IconStatusInformationOnlight,
  [CalloutType.KEY_INFORMATION]: IconStatusInformationOnlight,
  [CalloutType.ERROR]: IconStatusErrorOnlight,
  [CalloutType.WARNING]: IconStatusWarningOnlight,
  [CalloutType.SUCCESS]: IconStatusConfirmationOnlight,
};

const Callout = ({
  type = CalloutType.INFORMATION,
  children,
  marginBottom = 4,
  ...rest
}) => {
  const Icon = calloutTypeIconMap[type];

  const inner = (
    <div className={cn(styles.Callout, styles[type])}>
      <div className={styles.icon}>
        <Icon marginBottom={0} />
      </div>
      <div>{children}</div>
    </div>
  );

  return type === CalloutType.KEY_INFORMATION ? (
    inner
  ) : (
    <Panel type={type} marginBottom={marginBottom} {...rest}>
      {inner}
    </Panel>
  );
};

Callout.displayName = 'Callout';

Callout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    CalloutType.KEY_INFORMATION,
    CalloutType.INFORMATION,
    CalloutType.ERROR,
    CalloutType.SUCCESS,
    CalloutType.WARNING,
  ]),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export { Callout };

export default Callout;
