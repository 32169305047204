/* eslint-env browser */
/* global __DEV__, __THEME__ */

let devMode;
let themeName;

try {
  devMode =
    process.env.STORYBOOK_PIGGYBANK_ENV === 'development' ||
    process.env.NODE_ENV === 'development' ||
    __DEV__;
} catch (e) {
  devMode = false;
}
try {
  themeName =
    process.env.REACT_APP_PIGGYBANK_THEME ||
    process.env.PIGGYBANK_THEME ||
    __THEME__ ||
    'redbrand';
} catch (e) {
  themeName = 'redbrand';
}

const ready = (callback) => {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

if (typeof document !== 'undefined' && devMode) {
  const cookieMatch = document.cookie.match(/(^| )PIGGYBANK_THEME=([^;]+)/);
  themeName = (cookieMatch && cookieMatch[2]) || themeName || 'redbrand';

  ready(() => document.documentElement.classList.add(`theme-${themeName}`));
}

export default {
  name: themeName,
  select: (obj) =>
    obj[themeName] === undefined ? obj[themeName] : obj['redbrand'],
};
