import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getMarginBottomClass,
  marginBottomLevels,
} from '../../utils/marginBottom';
import { Provider } from '../ListItem/context';

import styles from './unordered-list.css';

export const UnorderedListType = {
  DISC: 'disc',
  CIRCLE: 'circle',
  SQUARE: 'square',
  TICK: 'tick',
  NONE: 'none',
};

const UnorderedList = ({
  type = UnorderedListType.DISC,
  spacing = 2,
  columns,
  marginBottom = 4,
  children,
  ...rest
}) => (
  <ul
    className={cn(
      styles.UnorderedList,
      {
        [styles[`columns${columns}`]]: !!columns,
      },
      getMarginBottomClass(marginBottom)
    )}
    {...rest}
  >
    <Provider
      value={{ type, spacing, parentElementType: 'ul', columns: !!columns }}
    >
      {children}
    </Provider>
  </ul>
);

UnorderedList.displayName = 'UnorderedList';

UnorderedList.propTypes = {
  type: PropTypes.oneOf([
    UnorderedListType.DISC,
    UnorderedListType.CIRCLE,
    UnorderedListType.SQUARE,
    UnorderedListType.TICK,
    UnorderedListType.NONE,
  ]),
  children: PropTypes.node,
  columns: PropTypes.oneOf([2, 3]),
  /**
   * Spacing of the `<ListItem/>`'s (0, 1, 2, 3, 4, 5, 6, 7)
   */
  spacing: PropTypes.oneOf(marginBottomLevels),
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

export { UnorderedList };

export default UnorderedList;
